<template>
<div class="action-block">
  <div class="common-action" @click="showModal">
    <i :class="block.icon"></i> {{ block.name }} <span v-if="waitContacts">({{ waitContacts }} in queue)</span>
  </div>

  <vuestic-modal
    :isOpen="isShowModal"
    @cancel="isShowModal = false"
    :okShown="false"
    :cancelShown="false"
    :force="true"
    cancelClass="btn btn-danger"
  >
    <span slot="title" class="text-primary font-weight-bold text-left">{{ block.name }}</span>
    <ValidationObserver 
      v-slot="{ handleSubmit, invalid, dirty }"
      ref="waitWorkFlowForm"
    >
      <form @submit.prevent="handleSubmit(save)" class="waitWorkFlowForm">
        <div class="row">
          <div class="col-12 mb-4">
            <text-input v-model="block.name"></text-input>
          </div>
          <div class="col-12 mb-4">
            <multiselect
              v-model="typeSelected"
              :options="typeOptions"
              :multiple="false"
              :close-on-select="true"
              :searchable="false"
              :allow-empty="false"
              :preserve-search="true"
              placeholder="Type"
              label="label"
              track-by="id"
              :show-labels="false"
            >
            </multiselect>
          </div>
          <div v-if="showLabel" class="col-md-12">
            <label class="control-label">{{ durationLabel }}</label>
          </div>
          <div class="col-6">
            <multiselect
              v-model="durationSelected"
              :options="durationOptions"
              :multiple="false"
              :close-on-select="true"
              :searchable="true"
              :allow-empty="false"
              :preserve-search="true"
              placeholder="Duration"
              label="label"
              track-by="id"
              :show-labels="false"
            >
            </multiselect>
          </div>
          <div class="col-6">
            <multiselect
              v-model="periodSelected"
              :options="periodOptions"
              :multiple="false"
              :close-on-select="true"
              :searchable="false"
              :allow-empty="false"
              :preserve-search="true"
              placeholder="Period"
              label="label"
              track-by="id"
              :show-labels="false"
            >
            </multiselect>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-primary" :disabled="invalid || processing" style="min-width: 80px;">
              <span>Save</span>
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </vuestic-modal>
</div>
</template>

<script>
export default {
  props: {
    block: {
      type: Object,
      default: null,
    },

    waitContacts: {
      type: Number,
      default: null,
    }
  },

  data() {
    return {
      processing: false,
      isShowModal: false,
      typeOptions: [
        { id: 'wait_for', label: 'Wait For' },
        { id: 'wait_for_reply', label: 'Wait For Reply' }
      ],
      typeSelected:null,
      periodOptions: [
        {id: 'seconds', label: 'second(s)'},
        {id: 'minutes', label: 'minute(s)'},
        {id: 'hours', label: 'hour(s)'},
        {id: 'days', label: 'day(s)'},
        {id: 'weeks', label: 'week(s)'},
        {id: 'months', label: 'month(s)'},
        {id: 'years', label: 'year(s)'},
      ],
      periodSelected:null,
      durationSelected: null,
    }
  },

  watch: {
    'block.options': {
      handler: function(newValue) {
        this.initValues();
      },
      deep: true,
    },
    'typeSelected': {
      handler: function(newValue, oldValue) {
        if (newValue && newValue != oldValue)
        {
          this.block.options.type = this.typeSelected.id
        }
      },
    },
    'periodSelected': {
      handler: function(newValue, oldValue) {
        if (newValue && newValue != oldValue)
        {
          this.block.options.period = this.periodSelected.id
        }
      },
    },
    'durationSelected': {
      handler: function(newValue, oldValue) {
        if (newValue && newValue != oldValue)
        {
          this.block.options.duration = this.durationSelected.id
        }
      },
    }
  },

  computed: {
    durationOptions() {
      return [...Array(60).keys()].map(item => {
        return {
          id: item + 1,
          label: item + 1
        }
      })
    },
    showLabel() {
      if (this.typeSelected)
        return ['wait_for_reply'].includes(this.typeSelected.id)
      return false
    },
    durationLabel() {
      let label = ''
      if (this.typeSelected)
      {
        switch(this.typeSelected.id)
        {
          case 'wait_for_reply':
            label = 'Expire In'
            break;
          default:
            break;
        }
      }
      return label
    }
  },

  mounted() {
    if (this.block.isNew) {
      this.block.isNew = false
      this.showModal()
    }
    this.initValues()
  },

  methods: {
    initValues() {
      this.typeSelected = this.typeOptions.find(item => item.id == this.block.options.type)
      this.periodSelected = this.periodOptions.find(item => item.id == this.block.options.period)
      this.durationSelected = this.durationOptions.find(item => item.id == this.block.options.duration)

      this.block.name = `${this.typeSelected && this.typeSelected.label } ${this.durationSelected && this.durationSelected.label} ${this.periodSelected && this.periodSelected.label}`
    },

    save() {
      this.isShowModal = false
    },

    showModal() {
      this.isShowModal = true
    },
  }
}
</script>

<style scoped lang="scss">
  .control-label {
    font-size: 0.6rem;
    font-weight: 600;
    text-transform: uppercase; 
  }
</style>